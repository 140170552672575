
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Form } from 'view-design'
import DataTypeSelector from '@/components/selector/data-type-selector.vue'
import BooleanSelector from '@/components/selector/boolean-selector.vue'
import { BaseConfig } from '@/api/maintain/base-config'
import { DataApiResult } from '@/libs/http-request'
import UploadButton from '@/components/upload-button.vue'
import PathInput from '@/components/path-input.vue'

@Component({
  name: 'ApplicationHideConfigModal',
  components: { DataTypeSelector, Component, BooleanSelector, UploadButton, PathInput }
})
export default class ApplicationAddConfigModal extends Vue {
  // 模块标题
  @Prop({
    type: String,
    required: true
  })
  readonly titleName!: string;

  // labelValue名字
  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly labelValueName!: string;

  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly configKey!: string

  /**
   * 配置项id
   */
  @Prop({
    type: Number,
    required: true
  })
  readonly configId!: number;

  /**
   * 数据类型
   */
   @Prop({
     type: String,
     required: true
   })
  readonly dataType!: string;

  /**
   * 保存方法
   */
  @Prop({
    type: Function,
    required: false
  })
  public onValueSave!: (
    configId: number,
    value: string,
    configKey?: string
  ) => Promise<DataApiResult<BaseConfig>>;

  isShow = false;
  loading = false;

  formData = {
    configValue: undefined
  } as {
    configValue: string|undefined;
  }

  /// 校验规则
  rules = {
    configValue: [
      {
        message: `${this.labelValueName || '变量'}不允许为空`,
        required: true,
        trigger: 'blur'
      }
    ]
  } as any;

  created () {
    const rule = this.getRuleByType(this.dataType)
    if (rule) {
      this.rules.configValue.push(rule)
    }
  }

  /** 上传的等待事件 */
  onLoading (val: boolean) {
    this.loading = val
  }

  /// 根据数据类型获取校验规则
  getRuleByType (type: string) {
    switch (type) {
      case 'string':
      case 'boolean':
        break
      case 'float':
        return {
          pattern: /^([1-9][0-9]*|0).?\d+$/,
          message: '输入格式错误,请输入数字',
          trigger: 'blur'
        }
      case 'integer':
        return {
          pattern: /^[1-9][0-9]*$/,
          message: '输入格式错误,请输入整数',
          trigger: 'blur'
        }
    }
  }

  /// 设置配置项值
  setValue (val: string) {
    this.formData.configValue = val
  }

  // 显示模态框，初始化数据
  show () {
    this.isShow = true
  }

  /** 关闭模态框 */
  close () {
    this.isShow = false
  }

  /** 取消事件 */
  doCancel () {
    this.$emit('onCancel')
  }

  /// 获取当前配置数据输入组件
  get dataComponentProps () {
    if (!this.dataType) {
      return {
        type: 'textarea',
        rows: 4
      }
    }
    switch (this.dataType) {
      case 'file':
      case 'path':
        return {}
      case 'string':
        return {
          type: 'password',
          password: true
        }
      case 'float':
      case 'integer':
      case 'boolean':
        return {
          style: 'width:200px'
        }
      default:
        return {
          type: 'password',
          password: true
        }
    }
  }

  /// 获取当前配置数据输入组件
  get dataConfigValueComponent () {
    if (!this.dataType) {
      return 'Input'
    }
    switch (this.dataType) {
      case 'string':
      case 'float':
      case 'integer':
        return 'Input'
      case 'boolean':
        return 'BooleanSelector'
      case 'file':
        return 'UploadButton'
      case 'path':
        return 'PathInput'
      default:
        return 'Input'
    }
  }

  /// 提交配置
  submitConfig () {
    const checkform = this.$refs.addConfigForm as Form
    checkform.validate((valid) => {
      if (valid && this.onValueSave) {
        this.loading = true
        this.onValueSave(this.configId, this.formData.configValue!, this.configKey)
          .then((res) => {
            this.loading = false
            this.$Notice.success({
              title: '成功',
              desc: '保存成功'
            })
            this.isShow = false
            this.$emit('onOk', res.data)
          })
          .catch((err) => {
            this.loading = false
            this.$Notice.error({
              title: '失败',
              desc: '保存失败: ' + err.message
            })
          })
      }
    })
  }
}
