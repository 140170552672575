
import { Prop, Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import AppDefaultConfigApi from '@/api/maintain/app-default-config'

 /** 实例选择模版组件 */
 @Component({
   name: 'InstanceChooseTemplateModal',
   components: { }
 })
export default class InstanceChooseTemplateModal extends Vue implements BaseModal {
  /** appId */
  @Prop({
    type: Number,
    required: true
  })
  appId!: number

  // 显示状态
  visiable = false
  /** 新的模版名称 */
  templateName = ''
  /** 应用配置模版 */
  configTemplateList: {label: string; value: string}[] = []

  mounted () {
    this.getConfigTemplateList()
  }

  getConfigTemplateList () {
    AppDefaultConfigApi.getAppTemplateNames(this.appId)
      .then((res) => {
        this.configTemplateList = (res.data as string[]).map(item => {
          return {
            label: item,
            value: item
          }
        })
      })
  }

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    if (!this.templateName) {
      this.$Message.error('请选择模版名称')
      return
    }
    this.$emit('onOk', this.templateName)
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
