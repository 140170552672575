
import { Prop, Vue, Component, Model } from 'vue-property-decorator'

// 选择组件
@Component({ name: 'BooleanSelector', components: {}, mixins: [] })
export default class BooleanSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: string

  curValue = this.value

  @Prop({
    type: String,
    default: '请选择'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
    if (!['true', 'false'].includes(this.curValue || '')) {
      this.initData()
    }
  }

  onChange (newValue: string) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData () {
    this.$emit('input', 'true')
    this.curValue = 'true'
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.datas = [
      {
        value: 'true',
        label: '是'
      },
      {
        value: 'false',
        label: '否'
      }]
  }
}
