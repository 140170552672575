import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult } from '@/libs/http-request'
import { BaseConfig } from './base-config'

/** 应用默认配置 */
export interface AppDefaultConfig extends BaseConfig {
  /** 应用实例id,如果appType为应用，是应用id,如果是image是imageId */
  appId?: number;
  /** 对应应用的团队id */
  teamId?: number;
  /** 应用模版名 */
  templateName?: string;
}

/**
  * 实例服务请求api接口
  */
class AppDefaultConfigApi extends DefaultApiService<AppDefaultConfig> {
  /**
   * 传入appDefaultconfigs数组并更新
   * @param appDefaultconfigs
   * @returns
   */
  updateByList (appDefaultconfigs: AppDefaultConfig[]) {
    return this.requestList('updateByList', appDefaultconfigs, 'POST')
  }

  /**
   * 取得用户的默认配置列表
   * @param appId
   */
  getApplicationDefaultConfigList (appId: number, templateName?: string) {
    return request<DataApiResult<AppDefaultConfig[]>>({
      url: this.baseUrlPath + `getApplicationDefaultConfigList/${appId}/${templateName || ''}`,
      method: 'GET'
    })
  }

  /** 根据id删除数据项 */
  deleteItem (appConfigId: number) {
    return request<DataApiResult<void>>({
      url: this.baseUrlPath + `${appConfigId}`,
      method: 'DELETE'
    })
  }

  /**
   * 修改对应对象的configValue字段
   * @param configId
   * @param configValue
   * @return
   */
  public updateValueById (configId: number, configValue: string) {
    return request({
      url: this.baseUrlPath + `updateConfigValue/${configId}/${configValue}`,
      method: 'GET'
    })
  }

  /**
   * 获取应用下所有模版名
   * @param appId
   */
  public getAppTemplateNames (appId: number) {
    return request({
      url: this.baseUrlPath + `appTempNames/${appId}`,
      method: 'GET'
    })
  }

  /**
   * 根据模版名复制应用默认模版
   * @param appId
   * @param oriTemplateName
   * @param newTemplateName
   * @returns
   */
  public cloneAppConfigTemplate (appId: number, oriTemplateName: string, newTemplateName: string) {
    return request({
      url: this.baseUrlPath + `cloneAppconfig/${appId}/${oriTemplateName}/${newTemplateName}`,
      method: 'GET'
    })
  }

  constructor () {
    super('/api/maintain/appdefaultconfigs')
  }
}

export default new AppDefaultConfigApi()
