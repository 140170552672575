
import { showModal } from '@/libs/modal-helper'
import modal from './instance-choose-template-modal.vue'

/**
  * 模版对话框
  * @returns
  */
export default function showInstanceChooseTemplateModal (appId: number): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    showModal<string>(modal, {
      props: {
        appId
      }
    }, true, (templateName: string) => {
      resolve(templateName)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
