import { render, staticRenderFns } from "./empty-page.vue?vue&type=template&id=531e9786&scoped=true&"
import script from "./empty-page.vue?vue&type=script&lang=ts&"
export * from "./empty-page.vue?vue&type=script&lang=ts&"
import style0 from "./empty-page.vue?vue&type=style&index=0&id=531e9786&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531e9786",
  null
  
)

export default component.exports