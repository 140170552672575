
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PropType } from 'vue'
import { BaseConfigType, BaseConfig } from '@/api/maintain/base-config'
import AttachmentFileDataApi from '@/api/maintain/attachment-filedata'

@Component({
  name: 'ConfigTag'
})
export default class extends Vue {
  /** 是否查看状态 */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly viewFlag!: boolean

  /** 配置数据 */
  @Prop({
    type: Object as PropType<BaseConfig>,
    required: true
  })
  readonly data!: BaseConfig

  /** 判断类型是应用(app) 实例(instance) */
  @Prop({
    type: String,
    required: false,
    default: 'app'
  })
  readonly type!: string;

  /** 应用类型 */
  BaseConfigType = BaseConfigType
  /** 文件名 */
  fileName = ''

  onModi () {
    if (!this.viewFlag) this.$emit('on-change')
  }

  onDownload () {
    this.$emit('on-download')
  }

  onClose () {
    if (!this.viewFlag) this.$emit('on-close', this.data)
  }

  /**
   * 获取配置项的显示文本
   */
  getConfigShowText (item: BaseConfig) {
    let text = ''
    const configKey = item.configKey
    const configValue = item.configValue || ''
    const configType = item.configType
    const configAlia = item.configAlia
    const aliaText = '【配置命名：' + (configAlia || '未设定别名') + '】'

    // 判断configValue是否为文件类型attr
    const index = configValue.indexOf('attr:')
    if (index === 0) {
      const fileId = configValue.slice(5, configValue.length)
      AttachmentFileDataApi.getItemById(parseInt(fileId)).then((res) => {
        this.fileName = configKey + '--->' + (res.name || '未知文件名') + aliaText
      })
    }

    // 根据配置类型，5种显示文本
    const res1 = item.hiddenValue ? aliaText : configValue + aliaText
    const res2 = item.hiddenValue ? configKey + aliaText : configKey + '--->' + configValue + aliaText
    const res3 = item.hiddenValue ? configKey + aliaText : configKey + '=' + configValue + aliaText
    const res4 = configValue
    const res5 = configValue + '--->' + configKey + aliaText

    switch (configType) {
      case 0: // from配置
      case 6: // CONFIGFILE配置文件
      case 7: // CONFIGDIR配置目录
      case 8: // CMD命令
      case 9: // Run运行指令
      case 10: // RESOURCE资源
      case 11: // ENTRYPOINT入口点
      case 12: // NET网络配置
      case 14: // WORKDIR工作目录
        text = res1
        break

      case 2: // volume挂载目录
        text = res5
        break

      case 1: // 端口
      case 3: // volume挂载文件
      case 18: // ADD
      case 19: // COPY
        text = res2
        break

      case 4: // arg配置
      case 5: // 容器ENV
      case 17: // 镜像ENV
        text = res3
        break

      case 15: // PRIVILEGED
      case 21: // RM_EXISTS
      case 22: // RESTART_POLICY
      case 23: // REMOVE
      case 24: // CPUSET
      case 26: // TERMINAL
        text = res4 || ''
        break

      case 25: // MEMORY_LIMIT
      case 29: // LOG_MAX_SIZE
        text = parseInt(res4 || '0') >= 1024 ? (parseInt(res4 || '0') / 1024).toFixed(2) + 'G' : parseInt(res4 || '0') + 'M'
        break

      default:
        text = res1
        break
    }
    // text过长则用省略号
    // text = text.length > 50 ? (text.substring(0, 50) + '...') : text
    return text
  }
}
