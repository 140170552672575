import { render, staticRenderFns } from "./config-tag.vue?vue&type=template&id=3227239b&scoped=true&"
import script from "./config-tag.vue?vue&type=script&lang=ts&"
export * from "./config-tag.vue?vue&type=script&lang=ts&"
import style0 from "./config-tag.vue?vue&type=style&index=0&id=3227239b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3227239b",
  null
  
)

export default component.exports