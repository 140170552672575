
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
@Component({
  name: 'pathInput'
})
export default class PathInput extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  value!: string

  // eslint-disable-next-line no-template-curly-in-string
  pre = '${atinstance_run_datadir}/'
  text = ''
  created () {
    if (this.value) {
      const arr = this.value.split('/')
      this.pre = arr[0] + '/'
      arr.shift()
      this.text = arr.join('/')
    } else {
      // eslint-disable-next-line no-template-curly-in-string
      this.pre = '${atinstance_run_datadir}/'
      this.text = ''
    }
    this.$emit('input', this.pre + this.text)
  }

  changeInput () {
    this.$emit('input', this.pre + this.text)
  }
}
