
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ConfigTag from '@/components/application/config-tag.vue'
import showApplicationAddConfigModal from '@/views/maintain/application-center/components/modal/show-application-add-config-modal'
import AppInstanceConfigDetailType, { BaseConfigType, BaseConfig } from '@/api/maintain/base-config'
import { DataApiResult } from '@/libs/http-request'
import EmptyPage from '@/components/empty-page.vue'
import AttachmentFileDataApi from '@/api/maintain/attachment-filedata'
import showTemplateModal from './template-modal'
import showInstanceChooseTemplateModal from './instance-choose-template-modal'
import AppDefaultConfigApi from '@/api/maintain/app-default-config'

@Component({
  name: 'ConfigForm',
  components: { ConfigTag, EmptyPage }
})
export default class extends Vue {
  @Prop({
    type: String,
    required: true
  })
  readonly cardTitle!: string

  /** appId */
  @Prop({
    type: Number,
    default: 0
  })
  readonly id!: number;

  /** 判断类型是应用(app) 实例(instance) */
  @Prop({
    type: String,
    required: false,
    default: 'app'
  })
  readonly type!: string;

  /** 类型为实例时, 实例当前的模版名称 */
  @Prop({
    type: String,
    default: ''
  })
  readonly instanceTemplateName!: string

  /** 是否查看状态 */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly viewFlag!: boolean

  /** 是否组件内控制编辑模式 */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly showEditButton!: boolean

   /** 保存方法 */
   @Prop({
     type: Function,
     required: true
   })
  public onSave!: (data: BaseConfig) => Promise<DataApiResult<BaseConfig>>

   /** 删除方法 */
   @Prop({
     type: Function,
     required: true
   })
  public onDelete!: (data: BaseConfig[]) => Promise<DataApiResult<void>>

   /** 加载数据方法 */
   @Prop({
     type: Function,
     required: true
   })
  public loadFromServer!: () => Promise<DataApiResult<BaseConfig[]>>

  /** 修改隐藏属性方法 */
  @Prop({
    type: Function,
    required: false
  })
  public onValueSave?: (configId: number, value: string, configKey?: string) => Promise<DataApiResult<BaseConfig>>

  /** 当前选中服务器的剩余内存 */
  @Prop({
    type: Number,
    default: -1
  })
  remainingMemory!: number

  /** 编辑模式 */
  editFlag = false
  /** 实例配置类型枚举 */
  BaseConfigType = BaseConfigType
  /** 实例配置类型详细信息 */
  AppInstanceConfigDetailType = AppInstanceConfigDetailType
  /** 高级配置 */
  advancedSetupFlag = false
  /** 配置数据 */
  configData: BaseConfig[] = []
  /** 当前网络模式 */
  netModel = 'bridge'
  /** 应用配置模版 */
  configTemplateList: {label: string; value: string}[] = []
  /** 当前应用的模版名称 */
  curTemplateName: null | string = null

  copyFireWallCmd () {
    const data = this.filterConfigData(BaseConfigType.PORT).map(item => {
      return `firewall-cmd --zone=public --add-port=${item.configValue}/tcp --permanent`
    })
    ;(this as any).$clipboard([...data, 'firewall-cmd --reload'].join(' && '))
    this.$Message.success('已复制到剪贴板')
  }

  copyCheckPortCmd () {
    const data = this.filterConfigData(BaseConfigType.PORT).map(e => e.configValue)
    ;(this as any).$clipboard(`lsof -i:${data.join(',')}`)
    this.$Message.success('已复制到剪贴板')
  }

  @Watch('id')
  onChangeId () {
    this.getConfigTemplateList()
  }

  async mounted () {
    if ((this.$refs.optionButton as Vue)?.$el?.parentElement?.style) {
      (this.$refs.optionButton as Vue).$el.parentElement!.style.padding = '0'
    }
    await this.getConfigTemplateList()
    this.getConfigData()
  }

  /** 获取应用下所有模版 */
  async getConfigTemplateList (templateName?: string) {
    const res = await AppDefaultConfigApi.getAppTemplateNames(this.id)
    // 如果存在需要加载的模版
    if (templateName) {
      this.selectTemplate({ label: templateName, value: templateName, tag: '' })
    } else {
      // 选中第一个模版
      this.$emit('changeTemplateName', (res.data as string[])[0])
      this.selectTemplate({ label: (res.data as string[])[0], value: (res.data as string[])[0], tag: '' })
    }
    this.configTemplateList = (res.data as string[]).map(item => {
      return {
        label: item,
        value: item
      }
    })
  }

  /** 选择template模版 */
  selectTemplate (data: {label: string; value: string; tag: string}) {
    this.curTemplateName = data.label
    this.$emit('changeTemplateName', data.value)
    this.getConfigData()
  }

  /** 模版复制 */
  addTemplate (templateName?: string) {
    (this.$refs.templateSelect as any).visible = false
    showTemplateModal(this.id, templateName).then((newTemplateName: string) => {
      if (!templateName) {
        this.configTemplateList.push({ label: newTemplateName, value: newTemplateName })
        this.curTemplateName = newTemplateName
        this.$emit('changeTemplateName', newTemplateName)
        this.configData = []
      } else {
        // 选中当前复制的模版
        this.getConfigTemplateList(newTemplateName)
      }
    })
  }

  /** 实例选择模版 */
  chooseTemplate () {
    showInstanceChooseTemplateModal(this.id).then((templateName: string) => {
      this.$emit('chooseTemplateName', templateName)
    })
  }

  @Watch('viewFlag')
  onViewFlag () {
    this.editFlag = !this.viewFlag
  }

  /** 设置高级模式 */
  setAdvancedSetupFlag (val: boolean) {
    this.advancedSetupFlag = val
  }

  /** 获取配置数据 */
  getConfigData () {
    if (this.loadFromServer) {
      this.loadFromServer().then((res) => {
        this.configData = res.data || []
        this.netModel = this.configData.filter((value: BaseConfig) => {
          return value.configType === BaseConfigType.NETWORKMODE
        })[0]?.configValue || 'bridge'
      }).catch((err) => {
        this.$Notice.error({
          title: '失败',
          desc: '数据获取失败: ' + err.message
        })
      })
    }
  }

  /** 过滤配置数据 */
  filterConfigData (configType: number): BaseConfig[] {
    const data = (this.configData as BaseConfig[]).filter((value: BaseConfig) => {
      if (!this.advancedSetupFlag) {
        return value.configType === configType && value.kernel
      }
      return value.configType === configType
    })
    return data.sort((a, b) => {
      if (a.configKey && b.configKey && a.configKey < b.configKey) return -1
      else return 1
    })
  }

  /** 修改tag标签 */
  checkTag (
    data: BaseConfig,
    fixedType: boolean,
    titleName: string,
    labelKeyName?: string,
    labelValueName?: string,
    keyAsValue?: boolean,
    customDataType?: string
  ) {
    showApplicationAddConfigModal(
      data,
      fixedType,
      titleName,
      this.remainingMemory,
      this.type,
      this.advancedSetupFlag,
      labelKeyName,
      labelValueName,
      keyAsValue,
      customDataType,
      this.onSave,
      this.onValueSave
    ).then(() => {
      this.getConfigData()
    })
  }

  /** 打开新增配置模态框 */
  openModal (
    data: BaseConfig,
    fixedType: boolean,
    titleName: string,
    labelKeyName?: string,
    labelValueName?: string,
    keyAsValue?: boolean,
    customDataType?: string
  ) {
    showApplicationAddConfigModal(
      data,
      fixedType,
      titleName,
      this.remainingMemory,
      this.type,
      this.advancedSetupFlag,
      labelKeyName,
      labelValueName,
      keyAsValue,
      customDataType,
      this.onSave
    ).then(() => {
      this.getConfigData()
    })
  }

  /** 关闭标签 */
  handleClose (data: BaseConfig) {
    if (this.onDelete) {
      this.onDelete([data]).then(() => {
        this.getConfigData()
        this.$Notice.success({
          title: '成功',
          desc: '删除成功'
        })
      }).catch((err) => {
        this.$Notice.error({
          title: '失败',
          desc: '删除失败: ' + err.message
        })
      })
    }
  }

  /** 修改网络模式 */
  changeNetWorkMode (val: string) {
    this.netModel = val
    const data = this.configData.filter((value: BaseConfig) => {
      return value.configType === BaseConfigType.NETWORKMODE
    })[0]
    data.configType = BaseConfigType.NETWORKMODE
    data.configValue = this.netModel
    this.onSave(data)
      .then(() => {
        this.$Notice.success({ title: '成功', desc: '切换网络模式成功' })
        this.getConfigData()
      }).catch((err) => {
        this.$Notice.error({ title: '失败', desc: err.message })
      })
  }

  /** 下载文件 */
  download (value: string) {
    const arr = value.split(':')
    if (arr[0] === 'attr') {
      /// 获取文件信息
      AttachmentFileDataApi.getItemById(parseInt(arr[1])).then((res) => {
        /// 下载文件
        const link = document.createElement('a')
        link.download = res.name!
        AttachmentFileDataApi.fullDown(parseInt(arr[1])).then((res) => {
          const blob = new Blob([res.data])
          link.href = window.URL.createObjectURL(blob)
          link.click()
          window.URL.revokeObjectURL(link.href)

          this.$Message.success('下载成功')
        }).catch(() => {
          this.$Message.error('下载失败')
        })
      })
    } else if (arr[0] === 'static' || arr[0] === 'placeholder') {
      /// 下载文件
      const link = document.createElement('a')
      link.download = arr[1]
      AttachmentFileDataApi.downStaticFile(arr[1]).then((res) => {
        const blob = new Blob([res.data])
        link.href = window.URL.createObjectURL(blob)
        link.click()
        window.URL.revokeObjectURL(link.href)

        this.$Message.success('下载成功')
      }).catch(() => {
        this.$Message.error('下载失败')
      })
    }
  }
}
