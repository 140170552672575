
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Form } from 'view-design'
import { PropType } from 'vue'
import DataTypeSelector from '@/components/selector/data-type-selector.vue'
import BooleanSelector from '@/components/selector/boolean-selector.vue'
import _ from 'lodash'
import { BaseConfig } from '@/api/maintain/base-config'
import { DataApiResult } from '@/libs/http-request'
import UploadButton from '@/components/upload-button.vue'
import PathInput from '@/components/path-input.vue'
import showApplicationHideConfigModal from '@/components/application/application-hide-config-modal'
import { AttachmentFileData } from '@/api/maintain/attachment-filedata'

@Component({
  name: 'ApplicationAddConfigModal',
  components: { DataTypeSelector, Component, BooleanSelector, UploadButton, PathInput }
})
export default class ApplicationAddConfigModal extends Vue {
  // 配置值是否为固定类型
  @Prop({
    type: Boolean,
    default: true
  })
  readonly fixedType!: boolean

  // 模块标题
  @Prop({
    type: String,
    required: true
  })
  readonly titleName!: string

  // 当前选中服务器剩余内存
  @Prop({
    type: Number,
    default: -1
  })
  remainingMemory!: number

  /** 判断类型是应用(app) 实例(instance) */
  @Prop({
    type: String,
    required: false,
    default: 'app'
  })
  readonly type!: string;

  /** 是否高级模式 */
  @Prop({
    type: Boolean,
    default: false
  })
  advancedSetupFlag!: boolean

  // labelKey名字
  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly labelKeyName!: string

  // labelValue名字
  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly labelValueName!: string

  /** 传入进来的数据，为空证明为新增 */
  @Prop({
    type: Object as PropType<BaseConfig>,
    required: true
  })
  readonly data!: BaseConfig

  /** 是否将key作为value处理 */
  @Prop({
    type: Boolean,
    default: false
  })
  readonly keyAsValue!: boolean

  /** 自定义dataType 用于区分原有的类型 */
  @Prop({
    type: String,
    default: undefined
  })
  readonly customDataType!: string

  /** 修改隐藏属性方法 */
 @Prop({
   type: Function,
   required: false
 })
  public onValueSave?: (configId: number, value: string, configKey?: string) => Promise<DataApiResult<BaseConfig>>

  /** 保存方法 */
  @Prop({
    type: Function,
    required: true
  })
  public onSave!: (newValue: BaseConfig, oldValue?: BaseConfig) => Promise<DataApiResult<BaseConfig>>

  isShow = false
  loading = false
  /** 几个单选框配置 单选框不允许使用布尔值作为value，单独进行处理 */
  kernel = (!this.advancedSetupFlag || this.data.kernel) ? 'yes' : 'no'
  cantModi = this.data.cantModi ? 'yes' : 'no'
  canDelete = this.data.canDelete ? 'yes' : 'no'
  hiddenValue = this.data.hiddenValue ? 'yes' : 'no'
  valueNullable = this.data.valueNullable ? 'yes' : 'no'
  addFormData: BaseConfig & { limitValue: string | undefined } = {
    ..._.cloneDeep(this.data),
    limitValue: this.data.configValue ? parseInt(this.data.configValue).toString() : undefined
  }

  /** 数据项类型
   * 判断data里的constraintDataType是否有值
   * 有则使用constraintDataType 无则使用dataType
   */
  dataType = this.customDataType ? this.customDataType : (this.data.constraintDataType ? this.data.constraintDataType : this.data.dataType)
  /** 当为容器最大内存时单位 */
  limitUnit = 'M'

  /** 新数据和旧数据比较 */
  get compareData () {
    /// 如果隐藏数据 则不比较configValue
    if (this.addFormData.hiddenValue) {
      return _.isEqual({ ...this.addFormData, configValue: null, version: null, mustModi: false }, { ...this.data, configValue: null, version: null, mustModi: false })
    }
    return _.isEqual(this.addFormData, this.data)
  }

  validateMemory (rule: any, value: string, callback: Function) {
    if (this.addFormData.configType === 29) callback()
    if (this.remainingMemory === -1) callback(new Error('当前服务器剩余内存获取失败, 请选择服务器或刷新页面后重试'))

    if (this.limitUnit === 'M' && parseInt(value) > this.remainingMemory) {
      callback(new Error(`当前填写内存大于服务器剩余内存, 服务器剩余内存为${this.remainingMemory}M(${(this.remainingMemory / 1024).toFixed(2)}G)`))
    } else if (this.limitUnit === 'G' && parseInt(value) * 1024 > this.remainingMemory) {
      callback(new Error(`当前填写内存大于服务器剩余内存, 服务器剩余内存为${this.remainingMemory}M(${(this.remainingMemory / 1024).toFixed(2)}G)`))
    }
    callback()
  }

  /// 表单校验规则
  get rules () {
    /// 基础校验规则
    const rules = {
      configKey: [
        {
          message: `${this.labelKeyName || '变量'}不允许为空`,
          required: true,
          trigger: 'blur'
        }
      ],
      configValue: [],
      limitValue: [
        {
          pattern: /^[1-9][0-9]*$/,
          message: '输入格式错误,请输入整数',
          trigger: 'blur'
        },
        {
          message: `${this.labelKeyName || '变量'}不允许为空`,
          required: true,
          trigger: 'blur'
        },
        { required: true, validator: this.validateMemory, trigger: 'blur' }
      ]
    } as any
    const extraValueRule = []
    /// 增加value非空校验
    extraValueRule.push({
      message: `${this.labelValueName || '值'}不允许为空`,
      required: this.valueNullable !== 'yes',
      trigger: this.addFormData.dataType === 'boolean' ? 'change' : 'blur'
    })
    /// 根据配置value数据类型追加额外校验条件
    if (this.addFormData.dataType) {
      const rule = this.getRuleByType(this.addFormData.dataType)
      if (rule) {
        extraValueRule.push(rule)
        if (this.keyAsValue) {
          rules.configKey.push(rule)
        }
      }
    }
    rules.configValue = rules.configValue.concat(extraValueRule)
    return rules
  }

  /** 修改配置值 */
  updateConfigValue () {
    if (this.getEditMode) {
      showApplicationHideConfigModal(
        this.addFormData.id!, this.titleName, this.addFormData.dataType!, this.labelValueName, this.onValueSave, this.data.configKey
      ).then((res) => {
        this.addFormData.version = res.version
        this.addFormData.mustModi = res.mustModi
        this.addFormData.configValue = res.configValue
      })
    }
  }

  /// 根据数据类型获取校验规则
  getRuleByType (type: string) {
    switch (type) {
      case 'string':
      case 'boolean':
        break
      case 'float':
        return {
          pattern: /^([1-9][0-9]*|0).?\d+$/,
          message: '输入格式错误,请输入数字',
          trigger: 'blur'
        }
      case 'integer':
        return {
          pattern: /^[1-9][0-9]*$/,
          message: '输入格式错误,请输入整数',
          trigger: 'blur'
        }
    }
  }

  /** 上传文件成功 */
  uploadOk (val: AttachmentFileData) {
    this.$set(this.addFormData, 'configValue', `attr:${val.id}`)
  }

  /** 上传等待事件 */
  onLoading (val: boolean) {
    this.loading = val
  }

  // 显示模态框，初始化数据
  show () {
    this.isShow = true
  }

  /** 关闭模态框 */
  close () {
    this.isShow = false
  }

  /** 取消事件 */
  doCancel () {
    this.$emit('onCancel')
  }

  /// 判断是否为新增 以data中的id判断
  get getEditMode () {
    if (this.data.id) return true
    return false
  }

  get getRadioMode () {
    if (this.type === 'app') return false
    return true
  }

  /// 获取当前配置数据输入组件
  get dataConfigValueComponent () {
    if (!this.dataType) {
      return 'Input'
    }
    switch (this.dataType) {
      case 'string':
      case 'float':
      case 'integer':
        return 'Input'
      case 'boolean':
        return 'BooleanSelector'
      case 'file':
        return 'UploadButton'
      case 'path':
        return 'PathInput'
      default:
        return 'Input'
    }
  }

  /// 获取当前配置数据输入组件
  get dataComponentProps () {
    if (!this.dataType) {
      return {
        type: 'textarea',
        rows: 2
      }
    }
    switch (this.dataType) {
      case 'file':
      case 'path':
        return {}
      case 'string':
        return {
          type: 'textarea',
          rows: 4
        }
      case 'float':
      case 'integer':
      case 'boolean':
        return {
          style: 'width:200px'
        }
      default:
        return {
          type: 'textarea',
          rows: 4
        }
    }
  }

  /// 提交配置
  submitConfig () {
    const checkform = this.$refs.addConfigForm as Form
    checkform.validate((valid) => {
      if (valid) {
        this.loading = true
        /// 判断值数据类型改变没 如果改变了则修改constraintDataType字段
        if (this.dataType !== this.addFormData.dataType) this.addFormData.constraintDataType = this.dataType
        /// 如果为容器最大运行内存 或 容器日志最大范围
        if (this.data.configType === 25 || this.data.configType === 29) {
          if (this.limitUnit === 'M') this.addFormData.configValue = parseInt(this.addFormData.limitValue || '0').toString()
          else if (this.limitUnit === 'G') this.addFormData.configValue = (parseInt(this.addFormData.limitValue || '0') * 1024).toString()
        }

        this.onSave(this.addFormData, this.data).then((res) => {
          this.loading = false
          this.$Notice.success({
            title: '成功',
            desc: '保存成功'
          })
          this.isShow = false
          this.$emit('onOk', res.data)
        }).catch((err) => {
          this.loading = false
          this.$Notice.error({
            title: '失败',
            desc: '保存失败: ' + err.message
          })
        })
      }
    })
  }

  /// 改变核心配置
  changeKernel (val: string) {
    if (val === 'yes') this.addFormData.kernel = true
    else this.addFormData.kernel = false
  }

  /// 改变用户修改权限
  changeCantModi (val: string) {
    if (val === 'yes') this.addFormData.cantModi = true
    else this.addFormData.cantModi = false
  }

  /// 改变用户删除权限
  changeCanDelete (val: string) {
    if (val === 'yes') this.addFormData.canDelete = true
    else this.addFormData.canDelete = false
  }

  /// 改变用户隐藏数据权限
  changeHiddenValue (val: string) {
    if (val === 'yes') this.addFormData.hiddenValue = true
    else this.addFormData.hiddenValue = false
  }

  /// 改变配置value是否允许为空
  changeValueNullable (val: string) {
    this.addFormData.valueNullable = val === 'yes'
  }
}
