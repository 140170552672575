import { showModal } from '@/libs/modal-helper'
import modal from './application-hide-config-modal.vue'

import { BaseConfig } from '@/api/maintain/base-config'
import { DataApiResult } from '@/libs/http-request'

/**
 * 显示应用配置模态框
 */
export default function showApplicationHideConfigModal (
  configId: number,
  titleName: string,
  dataType: string,
  labelValueName?: string,
  onValueSave?: (configId: number, value: string, configKey?: string) => Promise<DataApiResult<BaseConfig>>,
  configKey?: string): Promise<BaseConfig> {
  return new Promise<BaseConfig>((resolve, reject) => {
    showModal<BaseConfig>(modal, {
      props: {
        titleName: titleName,
        configId: configId,
        dataType: dataType,
        labelValueName: labelValueName,
        configKey: configKey,
        onValueSave: onValueSave
      }
    }, true, (res: BaseConfig) => {
      resolve(res)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
