
import { showModal } from '@/libs/modal-helper'
import modal from './template-modal.vue'

/**
  * 模版对话框
  * @returns
  */
export default function showCopyTemplateModal (appId: number, templateName?: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    showModal<string>(modal, {
      props: {
        appId,
        templateName
      }
    }, true, (newTemplateName) => {
      resolve(newTemplateName)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
