
import { Prop, Vue, Component, Model, Watch } from 'vue-property-decorator'

// 选择组件
@Component({ name: 'DataTypeSelector', components: {}, mixins: [] })
export default class DataTypeSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue = this.value || 'string'

  @Prop({
    type: String,
    default: '输入数据类型'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  onChange (newValue: string) {
    this.$emit('input', newValue)
    this.$emit('on-change', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  @Watch('value')
  watchValue (value?: string) {
    this.curValue = value || 'string'
  }

  initData () {
    this.$emit('input', 'string')
    this.curValue = 'string'
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.datas = [
      {
        value: 'string',
        label: '字符串'
      },
      {
        value: 'float',
        label: '数值'
      },
      {
        value: 'integer',
        label: '正整数'
      },
      {
        value: 'boolean',
        label: '布尔'
      }
      // {
      //   value: 'date',
      //   label: '日期'
      // },
      // {
      //   value: 'datetime',
      //   label: '日期时间'
      // }
    ]
  }
}
