import { showModal } from '@/libs/modal-helper'
import modal from './application-add-config-modal.vue'

import { BaseConfig } from '@/api/maintain/base-config'
import { DataApiResult } from '@/libs/http-request'

/**
 * 显示应用配置模态框
 */
export default function showApplicationAddConfigModal (
  data: BaseConfig,
  fixedType: boolean,
  titleName: string,
  remainingMemory: number,
  type: string,
  advancedSetupFlag: boolean,
  labelKeyName?: string,
  labelValueName?: string,
  keyAsValue?: boolean,
  customDataType?: string,
  onSave?: (newValue: BaseConfig, oldValue?: BaseConfig) => Promise<DataApiResult<BaseConfig>>,
  onValueSave?: (configId: number, value: string) => Promise<DataApiResult<BaseConfig>>): Promise<BaseConfig> {
  return new Promise<BaseConfig>((resolve, reject) => {
    showModal<BaseConfig>(modal, {
      props: {
        fixedType: fixedType,
        titleName: titleName,
        remainingMemory: remainingMemory,
        type: type,
        advancedSetupFlag: advancedSetupFlag,
        data: data,
        labelKeyName: labelKeyName,
        labelValueName: labelValueName,
        keyAsValue: keyAsValue,
        customDataType: customDataType,
        onSave: onSave,
        onValueSave: onValueSave
      }
    }, true, (res: BaseConfig) => {
      resolve(res)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      resolve({})
    })
  })
}
