
import { Prop, Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import AppDefaultConfigApi from '@/api/maintain/app-default-config'

 /** 复制模版组件 */
 @Component({
   name: 'TemplateModal',
   components: { }
 })
export default class TemplateModal extends Vue implements BaseModal {
  /** 模版名字 */
  @Prop({
    type: String || undefined,
    default: undefined
  })
  templateName: string | undefined

  /** appId */
  @Prop({
    type: Number,
    required: true
  })
  appId!: number

  // 显示状态
  visiable = false
  /** 新的模版名称 */
  createTemplateName = ''

  /** 提示信息 */
  get tip () {
    if (this.templateName) return `您确认要通过复制<span style="color: red">${this.templateName}</span>配置来创建新的配置版本吗?`
    return '您确认要创建新的配置版本吗?'
  }

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    if (!this.createTemplateName) {
      this.$Message.error('请填写模版名称')
      return
    }
    if (this.templateName) {
      AppDefaultConfigApi.cloneAppConfigTemplate(this.appId, this.templateName, this.createTemplateName)
        .then(() => {
          this.$Message.success('复制成功')
          this.$emit('onOk', this.createTemplateName)
        })
        .catch((err) => {
          this.$Message.error('复制失败: ' + err)
        })
    } else {
      this.$emit('onOk', this.createTemplateName)
    }
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
